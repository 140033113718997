export const userShowPath = function(user) {
  return window.router.makePath('user_show', { id: user.id });
};

export const accountRelatedMultipleContactsPath = function(accountId) {
  return window.router.makePath('related_multiple_contacts', { id: accountId });
};

export const adminEditPath = function() {
  return window.router.makePath('admin_edit');
};

export const adminSignOutPath = function() {
  return window.router.makePath('admin_sign_out');
};

export const MessageChainIndexPath = function() {
  return window.router.makePath('message_chain_index');
};

export const adminNotificationPreferencesPath = function() {
  return window.router.makePath('admin_notification_preferences');
};

export const accountSearchPath = function() {
  return window.router.makePath('accounts_search_path');
};

export const contactFetchAddressesPath = function(contactId) {
  return window.router.makePath('fetch_addresses', { id: contactId });
};

export const contactFetchAddressesByTypePath = function(contactId, contactType) {
  return window.router.makePath('fetch_addresses_by_type', { id: contactId, type: contactType });
};

export const contactShowPath = function(contact) {
  return window.router.makePath('contact_show', { id: contact.id });
};

export const contactEditPath = function(contact) {
  return window.router.makePath('contact_edit', { id: contact.id });
};

export const accountShowPath = function(account) {
  return window.router.makePath('account_show', { id: account.id });
};

export const orderShowPath = function(order) {
  return window.router.makePath('order_show', { code: order.code });
};

export const orderShowPathByCode = function(orderCode) {
  return window.router.makePath('order_show', { code: orderCode });
};

export const orderShowPathByCodeWithTabName = function(orderCode, tabName) {
  return window.router.makePath('order_show_with_tab', { code: orderCode, tab_name: tabName });
};

export const purchaseOrderShowPath = function(order, purchase_order) {
  return window.router.makePath('purchase_order_show', { order_code: order.code.toString().padStart(8, '0'), code: purchase_order.code });
};

export const purchaseOrderShowPathByCode = function(order_code, po_code) {
  return window.router.makePath('purchase_order_show', { order_code, code: po_code });
}

export const workOrderShowPath = function (workorder) {
  return window.router.makePath('work_order', { id: workorder.id });
};

export const previewWorkOrderPath = function (workorder) {
  return window.router.makePath('preview_work_order', { id: workorder.id });
};

export const previewSubWorkOrderPath = function (subWorkorder) {
  return window.router.makePath('preview_sub_work_order', { id: subWorkorder.id, work_order_id: subWorkorder.work_order.id });
};

export const workOrderShowPathFromId = function (workorderId) {
  return window.router.makePath('work_order', { id: workorderId });
};

export const checkListShowPath = function (checkList) {
  return window.router.makePath('check_list_item_show', { id: checkList.id, listable_type: checkList.listable_type, listable_id: checkList.listable_id });
};

export const workOrderCheckListItemPath = function(data) {
  return window.router.makePath('work_order_check_list_item_path', { id: data.id, work_order_id: data.work_order_id });
};

export const subWorkOrderShowPath = function (workorder, subWorkorder) {
  return window.router.makePath('sub_work_order', { work_order_id: workorder.id, id: subWorkorder.id });
};

export const orderCancelPath = function(order) {
  return window.router.makePath('cancel_order', { code: order.code });
};

export const orderDeletePath = function(order) {
  return window.router.makePath('delete_order', { code: order.code });
};

export const orderArchivePath = function(order) {
  return window.router.makePath('archive_order', { code: order.code });
};

export const orderEditPath = function(order) {
  return window.router.makePath('edit_order', { code: order.code });
};

export const estimateReviewPath = function(order, params = '') {
  return (window.router.makePath('current_estimate_review', { uuid: (Number(order.code) + 1).toString().padStart(8, '0') }) + params);
}

export const estimateReviewPdfPath = function(order, params = '') {
  return (window.router.makePath('current_estimate_pdf', { uuid: (Number(order.code) + 1).toString().padStart(8, '0') }) + params);
}

export const orderShowActivityPath = function(order) {
  return window.router.makePath('order_show', { code: order.code }) + '#activity';
};

export const orderShowWorkOrderPath = function(order) {
  return window.router.makePath('order_show', { code: order.code }) + '#work_orders';
};

export const workOrderEditPath = function (workorder) {
  return window.router.makePath('work_order_edit', { id: workorder.id });
};

export const workOrderEditPathWithFocus = function (workorder) {
  return window.router.makePath('work_order_edit_with_focus', { id: workorder.id });
};

export const subWorkOrderEditPathWithFocus = function (subWorkOrder) {
  return window.router.makePath('sub_work_order_edit_with_focus', { id: subWorkOrder.id, work_order_id: subWorkOrder.work_order.id });
};

export const archivedWorkOrdersPath = function() {
  return window.router.makePath('archived_work_orders');
}

export const estimatePdfPath = function (uuid) {
  return window.router.makePath('current_estimate_pdf', { uuid: uuid });
};

export const poPdfPath = function (uuid) {
  return window.router.makePath('current_po_issuance_review_pdf', { uuid: uuid });
};

export const workOrderTimeLogPath = function(workorder, timelog) {
  return window.router.makePath('work_order_time_log', { work_order_id: workorder.id, id: timelog.id });
};

export const workOrderDetailsPath = function(workorder) {
  return window.router.makePath('work_order_details', { id: workorder.id });
};

export const subWorkOrderDetailsPath = function(workorder, subWorkorder) {
  return window.router.makePath('sub_work_order_details', { work_order_id: workorder.id, id: subWorkorder.id });
}

export const workOrderSchedulesPath = function(workorderId) {
  return window.router.makePath('work_order_schedules', { id: workorderId });
};

export const subWorkOrderSchedulesPath = function(workorderId, subWorkOrderId) {
  return window.router.makePath('sub_work_order_schedules', { work_order_id: workorderId, id: subWorkOrderId });
};

export const newSubWorkOrderPath = function(workorderId) {
  return window.router.makePath('new_sub_work_order', { id: workorderId });
};

export const workOrderTasksCountPath = function(workorderId) {
  return window.router.makePath('work_order_tasks_count', { id: workorderId });
};

export const subWorkOrderTimeLogPath = function(subWorkOrder, timelog) {
  return window.router.makePath('work_order_sub_work_order_time_log', { work_order_id: subWorkOrder.work_order_id, sub_work_order_id: subWorkOrder.id, id: timelog.id });
};

export const fileTemplateDownloadPath = function (options) {
  return window.router.makePath('file_download_template_path', { template_type: options.template_type, product_ids_json: options.product_ids_json });
}

export const taskListing = function() {
  return window.router.makePath('task_listing');
};

export const workOrderListing = function() {
  return window.router.makePath('work_order_listing');
};

export const opportunityListing = function() {
  return window.router.makePath('opportunity_index');
};

export const estimateListing = function() {
  return window.router.makePath('estimate_index');
};

export const orderListing = function() {
  return window.router.makePath('orders_index');
};

export const purchaseOrdersListing = function() {
  return window.router.makePath('purchase_order_dashboard');
};

export const shipmentFilesListing = function() {
  return window.router.makePath('shipment_files_listing');
};

export const contactsListing = function() {
  return window.router.makePath('contact_listing');
};

export const accountsListing = function() {
  return window.router.makePath('account_listing');
};

export const parentsListing = function() {
  return window.router.makePath('parents_index');
};

export const designsListing = function() {
  return window.router.makePath('designs_index');
};

export const productsListing = function() {
  return window.router.makePath('products_index', { open_add_item_modal: false });
};

export const productShow = function (product) {
  return window.router.makePath('product_show', { id: product.id });
};

export const supplierListing = function() {
  return window.router.makePath('supplier_index');
};

export const shippingCalculator = function() {
  return window.router.makePath('shipping_calculator');
};

export const resourceListing = function() {
  return window.router.makePath('resourcing_listing');
};

export const resourceReportListing = function() {
  return window.router.makePath('resourcing_report_listing');
};

export const workOrderReportListing = function() {
  return window.router.makePath('work_order_report_listing');
};

export const grossProfitReportListing = function() {
  return window.router.makePath('gross_profit_report_listing');
}

export const workInProcessReportListing = function() {
  return window.router.makePath('work_in_process_report');
}

export const accountReceivableReportListing = function() {
  return window.router.makePath('account_receivable_report_listing');
}

export const unconfirmedPOReportListing = function() {
  return window.router.makePath('unconfirmed_po_report_listing');
};

export const poReportListing = function() {
  return window.router.makePath('po_report_listing');
};

export const departmentWiseRevenueReportListing = function() {
  return window.router.makePath('department_wise_revenue_report_listing');
};

export const newAccountRevenueReportListing = function() {
  return window.router.makePath('new_account_revenue_report_listing');
};

export const accountSpendReportListing = function() {
  return window.router.makePath('account_spend_report_listing');
};

export const overdueOrderReportListing = function() {
  return window.router.makePath('overdue_order_report_listing');
};

export const newContactRevenueReportListing = function() {
  return window.router.makePath('new_contact_revenue_report_listing');
};

export const latePoReportListing = function() {
  return window.router.makePath('late_po_report_listing');
};

export const userListing = function() {
  return window.router.makePath('user_listing');
};

export const departmentListing = function() {
  return window.router.makePath('department_listing');
};

export const sizeListing = function() {
  return window.router.makePath('size_listing');
};

export const rulesListing = function() {
  return window.router.makePath('rules_listing');
};

export const emailTemplateListing = function() {
  return window.router.makePath('email_templates_listing');
};

export const screenPrintingUpchargeListing = function() {
  return window.router.makePath('screen_printing_upcharges_listing');
};

export const screenPrintingLocationListing = function() {
  return window.router.makePath('screen_printing_location_listing');
};

export const productCustomizerListing = function() {
  return window.router.makePath('legacy_customizer_product_listing');
};

export const teamListing = function() {
  return window.router.makePath('account_team_index');
};

export const accountingAccountsListing = function() {
  return window.router.makePath('accounting_accounts_listing');
};

export const accountingOpportunitiesListing = function() {
  return window.router.makePath('accounting_opportunities_listing');
};

export const accountingOrdersListing = function() {
  return window.router.makePath('accounting_orders_listing');
};

export const accountingEstimatesListing = function() {
  return window.router.makePath('accounting_estimates_listing');
};

export const accountingPurchaseOrdersListing = function() {
  return window.router.makePath('accounting_purchase_orders_listing');
};

export const reviewInvoicePath = function(code, type) {
  return window.router.makePath('review_invoice_pdf', { code, type })
};

export const downloadInvoicePath = function(code, type) {
  if(type == 'draft') {
    return window.router.makePath('download_invoice_pdf', { code, type })
  } else {
    return window.router.makePath('download_invoice_attachment_pdf', { code })
  }
};

export const amTodosDashboard = function() {
  return window.router.makePath('am_todos_dashboard');
};

export const amTodosWorkOrders = function(param) {
  return window.router.makePath('am_todos_workorders', { param });
};

export const amTodosTasks = function(param) {
  return window.router.makePath('am_todos_tasks', { param });
};

export const amTodosOpportunities = function(param) {
  return window.router.makePath('am_todos_opportunities', { param });
};

export const amTodosActivities = function(param) {
  return window.router.makePath('am_todos_activities', { param });
};

export const amTodosEstimates = function(param) {
  return window.router.makePath('am_todos_estimates', { param });
};

export const amTodosOrders = function(param) {
  return window.router.makePath('am_todos_orders', { param });
};

export const amTodosEvents = function(param) {
  return window.router.makePath('am_todos_events', { param });
};

export const eventShow = function(event) {
  return window.router.makePath('event_show', { id: event.id });
};

export const homeviewsBizDevReportListing = function() {
  return window.router.makePath('homeviews_biz_dev_listing');
}

export const productConfigurationListing = function() {
  return window.router.makePath('product_configuration_listing');
};

export const holdInstructionsPath = function() {
  return window.router.makePath('hold_instructions_listing');
};

export const netsuiteLogsPath = function() {
  return window.router.makePath('netsuite_logs_listing');
};
