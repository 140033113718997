import gql from 'graphql-tag';

export const FETCH_NETSUITE_TOOLTIP_DATA = gql`
  query fetch_netsuite_data($id: String!) {
    fetch_netsuite_data(id: $id) {
      id
      sync_status
      formatted_sync_status
      last_netsuite_sync_at
      netsuite_id
      netsuite_link
      logs_path
    }
  }
`;
