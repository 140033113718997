import { FETCH_NETSUITE_TOOLTIP_DATA } from 'queries/netsuite/fetch_netsuite_tooltip_data.js';

export const fetchNetsuiteData = {
  fetchNetsuiteData(context, args) {
    return new Promise((res, rej) => {
      args.apollo.query({
        query: FETCH_NETSUITE_TOOLTIP_DATA,
        variables: {
          id: args.netsuiteId.toString()
        }
      }).then(response => {
        context.commit('updateNetsuiteData', response.data.fetch_netsuite_data);
        res();
      });
    });
  }
};
