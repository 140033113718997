import { createHelpers } from 'vuex-map-fields';

const { getGeneralConfiguration } = createHelpers({
  getterType: 'getGeneralConfiguration'
});

export const State = {
  currentUser: {},
  configurations: {},
  user_data: {},
  accountsData: {},
  contactsData: {},
  netsuiteData: {},
  topNavLinks: [],
  poShippingAddressData: {},
  ajaxRequestSent: false,
  currentTimeStamp: moment().valueOf()
};

export const Getters = {
  getGeneralConfiguration
};

